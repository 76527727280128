.infocard-img-inner {
    width: 100%;
    height: auto;
    border-radius: 32px;
    margin-bottom: 3%;
    margin-top: -3%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5) !important;
    transition: all 0.5s ease !important;
    z-index: 99999;
}

.infocard-img-inner-left:hover {
    -webkit-transform: rotate(3deg) scale(1.2) !important;
    transform: rotate(3deg) scale(1.2) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1) !important;
}

.infocard-img-inner-left {
    margin-left: -3%;
}

.infocard-img-inner-right:hover {
    -webkit-transform: rotate(-3deg) scale(1.2) !important;
    transform: rotate(-3deg) scale(1.2) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1) !important;
}

.infocard-img-inner-right {
    margin-left: 3%;
}

.infocard-text {
    display: flex;
    align-items: center;
}

.infocard-outerbox {
    background-color: #16E700;
    border-radius: 32px;
    margin-top: 3%;
    box-shadow: 0px 5px rgba(0, 0, 0, 0.5) !important
}

.infocard-outerbox-left {
    margin-left: 3%;
}

.infocard-outerbox-right {
    margin-right: 3%;
}

.infocard-text-right {
    width: 100%;
    text-align: right;
}